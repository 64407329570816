<template>
<div class="box-white-fiche" v-if="show_header(1)">
    <div class="row-column">
        <div class="col-child">
            <div class="bx-symbol">
                <div class="icon" v-if="data.icon_flags">
                    <img :alt="data.prix_model" :src="require('../../../assets/images/flag/new/'+data.icon_flags+'.svg')" width="30" height="30" style="margin: auto;"/>
                    <div class="status">{{data.prix_model ? data.prix_model.replace('Prix', '') : data.prix_model}}</div>
                </div>
            </div>
        </div>
        <div class="col-child" v-if="data.code">
            <div class="status-box" >
                <h3>{{$t('Drug code')}}</h3>
                <p >{{data.code}}</p>
            </div>
        </div>
        <div class="col-child" v-if="data.name">
            <div class="status-box" >
                <h3>{{$t('Drug name')}}</h3>
                <p >{{data.name}}</p>
            </div>
        </div>
        <div class="col-child" v-if="data.dci">
            <div class="status-box" >
                <h3>{{$t('DCI')}}</h3>
                <p>{{data.dci}}</p>
            </div>
        </div>
        <div class="col-child" v-if="data.firm">
            <div class="status-box" >
                <h3>{{$t('firm')}}</h3>
                <p>{{data.firm}}</p>
            </div>
        </div>
            <div class="col-child" v-if="data.atc">
            <div class="status-box" >
                <h3>{{$t('ATC')}}</h3>
                <p>{{data.atc}}</p>
            </div>
        </div>
    </div>
    <PDFModifyButton :agency="$route.params.agency"/>
</div>
<div class="box-white-fiche" v-if="show_header(2)">
    <div class="row-column">
        <div class="col-child" v-if="data.package">
            <div class="status-box" >
                <h3>{{$t('Packaging')}}</h3>
                <p >{{data.package}}</p>
            </div>
        </div>
        <div class="col-child" v-if="data.dosage_strength">
            <div class="status-box" >
                <h3>{{$t('Dosage strength')}}</h3>
                <p>{{data.dosage_strength}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.form">
            <div class="status-box" >
                <h3>{{$t('Form')}}</h3>
                <p>{{data.form}}</p>
            </div>
        </div>
        <div class="col-child"  v-if="data.units">
            <div class="status-box" >
                <h3>{{$t('Units')}}</h3>
                <p>{{data.units}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PDFModifyButton from './PDFModifyButton.vue'
export default {
    name : "HeaderForPrix",
    components : {
        PDFModifyButton
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
    },
    methods: {
        show_header(index){
            if(index===1){
                return this.data.icon_flags || this.data.code || this.data.name || this.data.dci || this.data.firm || this.data.atc 
            }
            if(index===2){
                return this.data.package || this.data.dosage_strength || this.data.form || this.data.units
            }
        },
        
    }
}
</script>

<style>

</style>